<template>
  <div>
    <el-row type="flex" align="middle" justify="space-between" class="search-wrap">
      <el-col :span="5">
        <label class="search-label">IP:</label>
        <el-select v-model="searchOption.ip" filterable clearable>
          <el-option v-for="item in ipList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <label class="search-label">地域:</label>
        <el-select v-model="searchOption.area_name" clearable>
          <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <label class="search-label">状态:</label>
        <el-select v-model="searchOption.status" clearable>
          <el-option label="正在创建" value="0"></el-option>
          <el-option label="空闲" value="1"></el-option>
          <el-option label="使用中" value="2"></el-option>
          <el-option label="已到期" value="3"></el-option>
          <el-option label="异常" value="4"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4" style="justify-content:space-between">
        <el-button class="fill-btn" @click="search(searchOption)">搜索</el-button>
        <el-button class="un-fill-btn" @click="resetForm(searchOption)">重置</el-button>
      </el-col>
    </el-row>
    <el-button style="float:right;margin-bottom:10px" class="un-fill-btn" :loading="btnLoading" @click="goResources">购买资源</el-button>
    
    <el-table
      :data="tableData"
      style="width: calc(100% - 2px);"
      height="70vh"
      row-class-name="tableRowClassName"
      v-loading="tableLoading"
    >
      <el-table-column type="index" label="序号" align="center" width="100px">
      </el-table-column>
      <el-table-column prop="ip" label="ID/名称" align="center">
        <template slot-scope="{row}">
          <div class="name-wrap">
            <div class="id">{{row.id_name}}</div>
            <div class="name">
              <div :class="row.name === '' ? 'gray' : ''">{{row.name === '' ? '修改名称' : row.name}} </div>
              <el-tooltip class="item" effect="light" content="修改" placement="top">
                <i class="el-icon-edit-outline" @click="openEditName(row)"></i>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="IP" align="center">
      </el-table-column>
      <el-table-column prop="browser_name" label="店铺" align="center">
      </el-table-column>
      <el-table-column label="云服务商/地区" align="center">
        <template slot-scope="{row}">
          <div class="name-wrap">
            <div class="id">{{row.cloud_service_provider}}</div>
            <div class="name">
              <div>{{row.area_name}} </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="time_limit" label="期限" align="center">
        <template slot-scope="{row}">
          <span>{{row.time_limit}}个月</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="status" label="状态" align="center" width="150">
        <template slot-scope="{row}">
          <div v-if="row.status === 0" class="resource-init">正在创建</div>
          <div v-if="row.status === 1" class="resource-delay">空闲</div>
          <div v-if="row.status === 2" class="resource-use">使用中</div>
          <div v-if="row.status === 3" class="resource-ex">已到期</div>
          <div v-if="row.status === 4" class="resource-err">异常</div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" sortable align="center">
      </el-table-column>
      <el-table-column prop="expire_time" label="到期时间" sortable align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row}">
          <el-button class="fill-btn" v-if="row.renewal_status === 1" @click="openRenew(row)">续费</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showDialog" width="50%">
      <div class="dialog-wrap">
        <div class="title">购买资源</div>
        <div class="shop-resource-desc">{{resourceDesc}}</div>
        <div class="padding" v-if="showDialog">
          <label>地域</label>
          <div class="sele-wrap">
            <div class="item" v-for="(item,index) in dyList" :key="item.id" :class="dyActive === index ? 'active-class':''" @click="processActive(index,'dyActive')">{{item.title}}</div>
          </div>
          <label>地区</label>
          <div class="sele-wrap">
            <div class="item" v-for="(item,index) in dyList[dyActive].children" :key="item.id" :class="dqActive === index ? 'active-class':''" @click="processActive(index,'dqActive')">{{item.title}}</div>
          </div>
          <label>云平台</label>
          <div class="sele-wrap">
            <div class="item" v-for="(item,index) in dyList[dyActive].children[dqActive].unit_price" :key="item.cloud_service_provider" :class="yunActive === index ? 'active-class':''" @click="processActive(index,'yunActive')">{{item.cloud_service_provider}}  ¥ {{item.price}}/月</div>
          </div>
          
          <label>期限</label>
          <div class="sele-wrap">
            <div class="item" v-for="(item,index) in qxList" :key="item.value" :class="qxActive === index ? 'active-class':''" @click="processActive(index,'qxActive','qxList')">{{item.label}}</div>
          </div>
          <div class="price-count-flex">
            <div>
              <label>数量</label>
              <div class="count-wrap">
                <el-input-number v-model="count" :min="1" :max="10"></el-input-number>
                <div class="desc">单次最多购买10个资源</div>
              </div>
            </div>
            <div>
              <label class="final-price">合计</label>
              <div class="sele-wrap">
                <div class="item active-class">¥ {{finalPrice}}</div>
              </div>
            </div>
          </div>
          <div style="display:flex;justify-content:center">
            <el-button class="fill-btn" :loading="btnLoading" @click="resourcesOrder">提交订单</el-button>
          </div>
          <div class="read">
            <el-checkbox v-model="checked"></el-checkbox>
            <div>我已阅读并同意 <span @click="toService">《技术服务协议》</span> 、<span @click="toRefund">《退款规则》</span> </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showPayDialog" v-if="showPayDialog" width="40%">
      <PayInfo :order="order" :type="'resources'" @closeAll="closeAll" />
    </el-dialog>
    <el-dialog :visible.sync="renewDialog" width="50%">
      <div class="dialog-wrap">
        <div class="title">资源续费</div>
        <div class="padding renew-dialog" v-if="renewDialog">
          <label>地域</label>
          <div class="sele-wrap">
            <div class="item renew-disable" v-for="(item,index) in dyList" :key="item.id" :class="dyActive === index ? 'active-class':''">{{item.title}}</div>
          </div>
          <label>地区</label>
          <div class="sele-wrap">
            <div class="item renew-disable" v-for="(item,index) in dyList[dyActive].children" :key="item.id" :class="dqActive === index ? 'active-class':''">{{item.title}}</div>
          </div>
          <label>云平台</label>
          <div class="sele-wrap">
            <div class="item renew-disable" v-for="(item,index) in dyList[dyActive].children[dqActive].unit_price" :key="item.cloud_service_provider" :class="yunActive === index ? 'active-class':''">{{item.cloud_service_provider}} ¥ {{item.price}}/月</div>
          </div>
          <!-- <label>价格</label>
          <div class="sele-wrap">
            <div class="item active-class">¥ {{finalPrice}}</div>
          </div> -->
          <label>期限</label>
          <div class="sele-wrap">
            <div class="item" v-for="(item,index) in qxList" :key="item.value" :class="qxActive === index ? 'active-class':''" @click="processActive(index,'qxActive','qxList')">{{item.label}}</div>
          </div>
          <div style="display:flex;justify-content:center">
            <el-button class="fill-btn" :loading="btnLoading" @click="resourceRenewOrder">提交订单</el-button>
          </div>
          <div class="read">
            <el-checkbox v-model="checked"></el-checkbox>
            <div>我已阅读并同意 <span @click="toService">《技术服务协议》</span> 、<span @click="toRefund">《退款规则》</span> </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="editNameDialog" width="40%" title="改名">
      <el-table :data="editNameRow">
        <el-table-column label="ID/名称" align="center">
          <template slot-scope="{row}">
            <div class="name-wrap">
              <div>{{row.id_name}}</div>
              <div>{{row.name}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="area_name" label="地区" align="center">
        </el-table-column>
        <el-table-column prop="expire_time" label="到期时间" align="center">
        </el-table-column>
      </el-table>
      <div class="edit-wrap">
        <span>新实例名称</span>
        <div style="width:70%">
          <el-input v-model="newName" :maxlength="30" class="edit-input"></el-input>
          <div class="edit-tips">您还可以输入{{30 - newName.length}}个字符</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="un-fill-btn" @click="editNameDialog = false">取 消</el-button>
        <el-button type="primary" class="fill-btn" @click="resourceEditName">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PayInfo from '@/components/pay-info.vue'
import {mapGetters} from 'vuex'
import {getResources,resourcesArea,resourcesOrder,resourceRenewOrder,resourceEditName} from '@/api/index'
import Fuse from "fuse.js";
export default {
  components:{
    PayInfo
  },
  data(){
    return{
      searchOption:{
        ip:'',
        area_name: '',
        status: ''
      },
      areaList: [],
      ipList: [],
      tableLoading: false,
      btnLoading: false,
      tableData: [],
      showDialog: false,
      showPayDialog: false,
      renewDialog: false,
      editNameDialog: false,
      editNameRow: [],
      newName: '',
      dyList: [],
      dyActive: 0,
      dqList: [],
      dqActive: 0,
      yunList: [],
      yunActive: 0,
      qxList: [
        {
          label: '一个月',
          value: '1'
        },
        {
          label: '三个月',
          value: '3'
        },
        {
          label: '六个月',
          value: '6'
        },
        {
          label: '十二个月',
          value: '12'
        },
      ],
      qxActive: 0,
      count: 1,
      order: {},
      renewData:{},
      checked: false,
      timer: null,
      resourceDesc: ''
    }
  },
  computed:{
    ...mapGetters(['isRefreshData']),
    finalPrice(){
      // return `${Number(this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].price)} - ${Number(this.qxList[this.qxActive].value)} - ${this.count}`
      let price = Math.floor(Number(this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].price) * Number(this.qxList[this.qxActive].value) * this.count * 100) / 100 
      return price.toFixed(2)
    }
  },
  watch:{
    async isRefreshData(newValue){
      if(newValue){
        try{
          await this.getResources()
          this.$store.commit('index/SET_ISREFRESH',false)
          this.$message.success('数据刷新成功')
        }catch(err){
          this.$store.commit('index/SET_ISREFRESH',false)
          this.$message.error('数据刷新失败')
        }
      }
    },
    dyActive(val){
      if(!this.renewDialog){ // 续费操作除外
        this.dqActive = 0
        this.yunActive = 0
      }
    },
    dqActive(val){
      if(!this.renewDialog){
        this.yunActive = 0
      }
    },
  },
  async mounted(){
    this.getResources()
    try{
      let data = await this.resourcesArea()
      let arr = []
      data.lists.forEach(items => {
        items.children.forEach(item => {
          arr.push(item.title)
        })
      })
      this.areaList = arr
    }catch(err){
      console.log(err)
    }
  },
  destroyed(){
    clearTimeout(this.timer)
  },
  methods:{
    async search(form){
      console.log(form)
      await this.getResources()
      if(form.ip === '' && form.area_name === '' && form.status === ''){
        this.getResources()
      }else{
        let filter=(condition,data)=>{
          return data.filter( item => {
            return Object.keys( condition ).every( key => {
              return String( item[ key ] ).toLowerCase().includes(
                String( condition[ key ] ).trim().toLowerCase() )
                } )
          } )
        }
        //多条件
        var cc = filter(form,this.tableData);
        this.tableData = cc
      }
    },
    resetForm(form){
      this.getResources()
      Object.keys(form).forEach(item => {
        form[item] = ''
      })
    },
    processActive(index,activeKey){
      this[activeKey] = index
    },
    toService(){
      window.open('https://www.etsyzs.com/service')
    },
    toRefund(){
      window.open('https://www.etsyzs.com/refund/')
    },
    getResources(){
      return new Promise((resolve,reject) => {
        this.tableLoading = true
        getResources().then(res => {
          let {result} = res
          let data = this.$rsaDecrypt(result)
          console.log('资源列表',data)
          this.tableData = data.lists
          let ipArr = []
          data.lists.forEach(item => {
            if(item.ip !== ''){
              ipArr.push(item.ip)
            }
          })
          this.ipList = ipArr
          this.tableLoading = false
          data.lists.forEach(item => {
            if(item.status === 0){
              this.timer = setTimeout(() => {
                this.getResources()
              },60000)
            }else{
              clearTimeout(this.timer)
            }
          })
          resolve(data)
        }).catch(err => {
          this.tableLoading = false
          console.log(err)
          clearTimeout(this.timer)
          reject(err)
        })
      })
    },
    openEditName(row){
      this.editNameRow = [row]
      this.editNameDialog = true
    },
    resourceEditName(){
      let req = {
        resources_id: this.editNameRow[0].id,
        name: this.newName
      }
      let requestData = this.$rsaEncryptForm(req, "SIJa%$%3hu12hu(*1278")
      resourceEditName(requestData).then(res => {
        this.$message.success('修改成功')
        this.editNameDialog = false
        this.getResources()
      }).catch(err => {
        console.log(err)
      })
    },
    async goResources(){
      this.dyActive = 0
      this.dqActive = 0
      this.yunActive = 0
      this.btnLoading = true
      try{
        await this.resourcesArea()
        this.btnLoading = false
      }catch(err){
        this.btnLoading = false
      }
      this.showDialog = true
    },
    resourcesArea(){
      return new Promise((resolve,reject) => {
        resourcesArea().then(res => {
          let {result} = res
          let data = this.$rsaDecrypt(result)
          console.log(data)
          this.resourceDesc = data.tip
          this.dyList = data.lists
          resolve(data)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    resourcesOrder(){
      if(this.checked){
        this.btnLoading = true
        let req = {
          payment_amount: this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].price,
          // payment_amount: '0.01',
          resources_area_id: this.dyList[this.dyActive].children[this.dqActive].id,
          resources_price_id: this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].price_id,
          number: this.count,
          time_limit: this.qxList[this.qxActive].value
        }
        console.log(req)
        let requestData = this.$rsaEncryptForm(req, "SIJa%$%3hu12hu(*1278")
        resourcesOrder(requestData).then(res => {
          console.log(res)
          this.btnLoading = false
          this.order = {
            payment_amount: this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].price,
            resources_area: `${this.dyList[this.dyActive].title}-${this.dyList[this.dyActive].children[this.dqActive].title}`,
            cloud_service_provider: this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].cloud_service_provider,
            time_limit: this.qxList[this.qxActive].label,
            number: this.count,
            total_amount: res.result.total_amount,
            alipay_qr_code: res.result.alipay_qr_code,
            order_no: res.result.order_no,
            isRenew: false
          }
          this.showPayDialog = true
        }).catch(err => {
          this.btnLoading = false
        })
      }else{
        this.$message.error('请勾选并同意协议和规则！')
      }
    },
    async openRenew(row){
      this.tableLoading = true
      try{
        await this.resourcesArea()
        this.tableLoading = false
        this.renewDialog = true
      }catch(err){
        this.tableLoading = false
        return false
      }
      console.log(row)
      this.renewData.resources_id = row.id
      let countryIndex,provinceIndex,yunIndex
      this.dyList.forEach((items,index) => {
        items.children.forEach(item => {
          if(item.title === row.area_name){
            countryIndex = index
          }
        })
      })
      let dq = this.dyList[countryIndex].children.findIndex(item => item.title === row.area_name)
      if(dq !== -1){
        provinceIndex = dq
      }
      let yun = this.dyList[countryIndex].children[provinceIndex].unit_price.findIndex(item => item.cloud_service_provider === row.cloud_service_provider)
      if(yun !== -1){
        yunIndex = yun
      }
      
      this.dyActive = countryIndex
      this.dqActive = provinceIndex
      this.yunActive = yunIndex
    },
    resourceRenewOrder(){
      if(this.checked){
        this.btnLoading = true
        this.renewData.time_limit = this.qxList[this.qxActive].value
        let requestData = this.$rsaEncryptForm(this.renewData, "SIJa%$%3hu12hu(*1278")
        resourceRenewOrder(requestData).then(res => {
          this.btnLoading = false
          const {result} = res
          this.order = {
            payment_amount: this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].price,
            resources_area: `${this.dyList[this.dyActive].title}-${this.dyList[this.dyActive].children[this.dqActive].title}`,
            cloud_service_provider: this.dyList[this.dyActive].children[this.dqActive].unit_price[this.yunActive].cloud_service_provider,
            time_limit: this.qxList[this.qxActive].label,
            number: this.count,
            total_amount: result.total_amount,
            alipay_qr_code: result.alipay_qr_code,
            order_no: result.order_no,
            isRenew: true
          }
          this.showPayDialog = true
        }).catch(err => {
          this.btnLoading = false
        })
      }else{
        this.$message.error('请勾选并同意协议和规则！')
      }
    },
    closeAll(){
      this.showPayDialog = false
      this.showDialog = false
      this.renewDialog = false
      this.getResources()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';
.search-wrap{
  padding-bottom: 30px;
  .el-col{
    display: flex;
    align-items: center;
    .search-label{
      white-space: nowrap;
      padding-right: 10px;
    }
  }
}
.resource-use,.resource-ex,.resource-err,.resource-init,.resource-delay{
  width: 90px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  margin: 0 auto;
}
.resource-init{
  background-color: rgb(219,219,219);
  color: #000;
}
.resource-delay{
  background-color:  #1e9fff;
  color: #fff;
}
.resource-use{
  background-color: rgb(214,246,221);
  color: rgb(50, 210, 85);
}
.resource-ex{
  background-color: #ffb800;
  color: #fff;
}
.resource-err{
  background-color: rgb(255,221,221);
  color: #FD5353
}
.renew-disable{
  background:#c8c9cc !important;
  color: #fff !important;
  cursor: not-allowed !important;
}
.dialog-wrap{
  padding: 0px 10px;
  .title{
    font-size: 20px;
    color: #FA8120;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
    position: relative;
    text-align: center;
    &::before{
      content: '';
      width: 80px;
      height: 3px;
      background-color: #FA8120;
      position: absolute;
      left: 50%;
      bottom: -1px;
      transform: translateX(-50%);
    }
  }
  .padding{
    // padding: 0 55px;
    margin: 30px 0;
    label{
      font-size: 18px;
      margin-bottom: 14px;
      display: block;
    }
    .sele-wrap{
      padding-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      .item{
        width: 170px;
        height: 48px;
        background: #FEF6F0;
        border-radius: 6px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        color: #828282;
        margin-right: 30px;
        margin-bottom: 20px;
      }
      .active-class{
        border: 1px solid #ED514C;
        background: #fff !important;
        color: #ED514C !important;
        position: relative;
        &::before{
          content: '';
          width: 16px;
          height: 16px;
          background-image: url('~@/assets/imgs/month_check.png');
          background-size: 100% 100%;
          position: absolute;
          right: -1px;
          bottom: 0;
          border-bottom-right-radius: 6px;
        }
      }
    }
    .count-wrap{
      display: flex;
      align-items: center;
      .desc{
        padding-left: 50px;
      }
    }
    .fill-btn{
      width: 400px;
      margin: 0px 0 30px 0;
    }
    .read{
      display: flex;
      align-items: center;
      justify-content: center;
      .el-checkbox{
        margin-bottom: unset;
      }
      div{
        padding-left: 20px;
        span{
          color: #548AF6;
          cursor: pointer;
        }
      }
      
    }
  }
  
}
::v-deep{
    .el-dialog{
      margin-top: unset !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    .el-input-number__decrease,.el-input-number__increase{
      background-color: #FEF6F0;
    }
}
.final-price{
  // padding-top: 20px;
}
.name-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .id{
    margin-bottom: 20px;
  }
  .name{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .gray{
      color: #c2c2c2;
    }
    i{
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
.edit-wrap{
  width: 100%;
  display: flex;
  span{
    font-size: 18px;
    line-height: 44px;
    white-space: nowrap;
    padding-right: 20px;
  }
  .edit-input{
    width: 100%;
  }
  .edit-tips{
    padding-top: 20px;
  }
}
.price-count-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop-resource-desc{
  font-size: 18px;
  color: #ED514C;
  margin-top: 20px;
  line-height: 30px;
  text-indent: 38px;
}
</style>